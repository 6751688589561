import { buscarProdutosSelecao } from '../../../common/resources/produto';
import { buscarHoldings, buscarClientes, buscarTodasRegionais } from '../../../common/resources/planejamento/planejamento-acao-cadastro';
import { buscarAcaoProjetoSelecao } from '../../../common/resources/acao-projeto';

export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      anoFiscalResource: this.$api.anoFiscal(this.$resource),
      extensaoResource: this.$api.extensao(this.$resource),
      tipoInvestimentoResource: this.$api.tipoInvestimento(this.$resource),
      centroCustoResource: this.$api.centroCusto(this.$resource),
      clienteResource: this.$api.cliente(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      tipoBeneficioResource: this.$api.tipoBeneficio(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),

      statusPlanejamentoAcao: [
        {
          texto: this.$t('status_entidade.em_cadastro'),
          valor: 'EM_CADASTRO',
        },
        {
          texto: this.$t('status_entidade.aguardando_aprovacao'),
          valor: 'AGUARDANDO_APROVACAO',
        },
        {
          texto: this.$tc('status_entidade.aprovado', 1),
          valor: 'APROVADO',
        },
        {
          texto: this.$tc('status_entidade.reprovado', 1),
          valor: 'REPROVADO',
        },
        {
          texto: this.$t('status_entidade.em_analise'),
          valor: 'EM_ANALISE',
        },
        {
          texto: this.$t('status_entidade.cancelado'),
          valor: 'CANCELADO',
        },
      ],
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'categoria_acao',
            nomCampoId: 'categoria_acao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoListagemResource
                .buscarCategoriaAcao({ autocomplete }),
              itemValue: 'categoria_acao',
              itemText: 'categoria_acao',
            },
          },
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'descricao',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: this.statusPlanejamentoAcao,
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'periodo_apuracao',
            nomCampoId: 'periodo_apuracao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('label.unica_vez', 1)}`, valor: 'UNICA_VEZ' },
                  { texto: `${this.$tc('label.anual', 1)}`, valor: 'ANUAL' },
                  { texto: `${this.$tc('label.trimestral', 1)}`, valor: 'TRIMESTRAL' },
                  { texto: `${this.$tc('label.mensal', 1)}`, valor: 'MENSAL' },
                  { texto: `${this.$tc('label.especifico', 1)}`, valor: 'ESPECIFICO' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'calculo_apuracao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              lista: [
                { texto: `${this.$tc('label.sellin_valor', 1)}`, valor: 'SELLIN_VALOR' },
                { texto: `${this.$tc('label.sellin_volume', 1)}`, valor: 'SELLIN_VOLUME' },
                { texto: `${this.$tc('label.sellout_valor', 1)}`, valor: 'SELLOUT_VALOR' },
                { texto: `${this.$tc('label.sellout_volume', 1)}`, valor: 'SELLOUT_VOLUME' },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
          },
          {
            labelCampo: 'base_calculo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              lista: [
                { texto: `${this.$tc('label.valor_liquido', 1)}`, valor: 'VALOR_LIQUIDO' },
                { texto: `${this.$tc('label.valor_bruto', 1)}`, valor: 'VALOR_BRUTO' },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
          },
          {
            labelCampo: 'forma_bonificacao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              lista: [
                { texto: `${this.$tc('label.fixo', 1)}`, valor: 'FIXO' },
                { texto: `${this.$tc('label.percentual', 1)}`, valor: 'PERCENTUAL' },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
          },
          {
            labelCampo: 'calculo_bonificacao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              lista: [
                { texto: `${this.$tc('label.sellin', 1)}`, valor: 'SELLIN' },
                { texto: `${this.$tc('label.sellout', 1)}`, valor: 'SELLOUT' },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = { autocomplete };
                return buscarTodasRegionais(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'periodo_planejamento',
            nomCampoId: 'id_periodo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependentes: ['periodo_orcamentario'],
            },
            async: {
              fetchFunction: (autocomplete) => this.anoFiscalResource.buscar({ autocomplete }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'centro_custo',
            nomCampoId: 'id_centro_custo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresDependencias(),
                };
                return this.centroCustoResource.selecao(parametros);
              },
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'produto',
            nomCampoId: 'id_produto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresDependencias(),
                };
                return buscarProdutosSelecao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codigoNome',
            },
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => this.tipoAcaoResource.buscarAtivos(),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'tipo_verba',
            nomCampoId: 'id_tipo_verba',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (filter) => this.orcamentoTipoVerbaResource.buscarPorVisao({ filter }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'tipo_pagamento',
            nomCampoId: 'id_tipo_pagamento',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.tipoBeneficioResource
                .listarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'unidadenegocio',
            nomCampoId: 'id_unidadenegocio',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarUnidadesNegocioPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'nota_debito',
            nomCampoId: 'num_nota_debito',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'cod_acao',
            nomCampoId: 'cod_acao',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'projeto',
            nomCampoId: 'id_acao_projeto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                };
                return buscarAcaoProjetoSelecao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nomFiltro',
            },
          },
        ],
      },
      camposFoco: {
        padrao: [
          {
            labelCampo: 'regional',
            nomCampoId: 'id_regional',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = { autocomplete };
                return buscarTodasRegionais(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'id_grupo_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'fornecedor',
            nomCampoId: 'id_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'holding',
            nomCampoId: 'id_holding',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.containerFoco.getValoresCamposPadrao(),
                };
                return buscarHoldings(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'cliente',
            nomCampoId: 'id_cliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                };
                return buscarClientes(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
        ],
      },
    };
  },
};
