<template>
  <div>
    <v-card class="PlanejamentoAcaoDados">

      <v-card-title class="mb-3">
        <div class="title">{{ $tc('title.acao', 2) }}</div>
        <planejamento-acao-dados-agrupamento
          ref="agrupamento"
          @PlanejamentoAcaoDadosAgrupamento_Alterado="alterarAgrupamento"
        />
      </v-card-title>
      <v-expansion-panels
        accordion
        focusable
        v-if="!iniciandoPainel && !isAgrupamentoCustomizado(agrupamentoSelecionado)"
        :key="pagina">
        <v-expansion-panel
          v-for="(tipoAcao, index) in tiposAcao"
          :key="index">
          <v-expansion-panel-header>
            {{ tituloDoPainel(tipoAcao) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <planejamento-acao-tabela
              :tipo-acao="tipoAcao"
              :ordenacao="parametrizacaoAcao[tipoAcao.id]"
              :label-campo-cliente="labelsCampoCliente[tipoAcao.id]"
              :filtros="filtrosAplicados"
              :agrupamento="filtrosAplicados.agrupamento"
              :ref="`tabela-${tipoAcao.id}`"
              @refazer_busca="aplicaFiltros({})"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <component v-for="(agrupamento, index) in agrupamentosHabilitados" :key="index"
                 :is="agrupamento.componente"
                 :ref="agrupamento.nome"
                 v-if="agrupamentoSelecionado === agrupamento.nome"
                 :filtros="filtrosAplicados"
                 @PlanejamentoAcaoTabelaCustom_Configurado="configuraPaginacao">
      </component>
    </v-card>

    <div class="text-xs-center mt-3" v-if="totalPagina > 1">
      <v-pagination
        v-model="pagina"
        :length="totalPagina"
        :total-visible="$vuetify.breakpoint.mdAndUp ? 15 : 7"
        @input="aplicaFiltros(filtrosAplicados)"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import planejamentoDefinicaoAgrupamentos from '@/spa/planejamento-acao/list/planejamentoDefinicaoAgrupamentos';
import PlanejamentoAcaoTabela from './PlanejamentoAcaoTabela';
import PlanejamentoAcaoDadosAgrupamento from './PlanejamentoAcaoDadosAgrupamento';
import { buscarTiposAcao } from '../../../common/resources/planejamento/planejamento-acao-listagem';

export default {
  name: 'PlanejamentoAcaoDados',
  components: {
    PlanejamentoAcaoTabela,
    PlanejamentoAcaoDadosAgrupamento,
    ...planejamentoDefinicaoAgrupamentos.componentes,
  },
  data() {
    return {
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),

      tiposAcao: [],
      painel: null,
      pagina: 1,
      totalPagina: 1,
      quantidadePagina: 15,
      iniciandoPainel: true,
      agrupamentoSelecionado: 'tipoAcao',

      filtrosAplicados: {},
      parametrizacaoAcao: {},
      labelsCampoCliente: {},
    };
  },
  computed: {
    agrupamentosHabilitados() {
      return planejamentoDefinicaoAgrupamentos
        .agrupamentosHabilitados(this);
    },
  },
  methods: {
    async carregaParametrosTiposAcao(tiposAcao) {
      await Promise.all(tiposAcao
        .map((tipo) => this.configuracaoResource
          .buscarCamposGrid({ idTipoAcao: tipo.id })
          .then((res) => {
            this.parametrizacaoAcao[tipo.id] = res.data;
          })));
    },
    async carregaLabelCampoCliente(tiposAcao) {
      await Promise.all(tiposAcao
        .map((tipo) => this.configuracaoResource
          .buscarLabelCampoCliente({ idTipoAcao: tipo.id })
          .then((res) => {
            this.labelsCampoCliente[tipo.id] = res.bodyText;
          })));
    },
    isAgrupamentoCustomizado(agrupamento) {
      return planejamentoDefinicaoAgrupamentos
        .isAgrupamentoCustomizado(agrupamento);
    },
    tituloDoPainel(tipoAcao) {
      let titulo = `${this.$t('label.tipo_acao')} :: ${tipoAcao.nome}`;

      if (tipoAcao.agrupamento) {
        const { tipoAgrupamento, agrupamento } = tipoAcao;
        let labelTipo;

        if (['cliente', 'fornecedor'].indexOf(tipoAgrupamento) >= 0) {
          labelTipo = this.$t(`label.${tipoAgrupamento}`);
        } else {
          labelTipo = tipoAgrupamento;
        }

        titulo += ` :: ${labelTipo} :: ${agrupamento}`;
      }
      return titulo;
    },
    alterarAgrupamento() {
      this.pagina = 1;
      this.agrupamentoSelecionado = null;
      this.$emit('PlanejamentoAcaoDados__AplicaFiltros');
    },
    aplicaFiltros(filtros, aplicado) {
      this.tiposAcao = null;
      this.painel = null;
      const agrupamento = this.$refs.agrupamento.agrupamentoHabilitado();

      this.filtrosAplicados = {
        ...filtros,
        numeroPagina: aplicado ? 1 : this.pagina,
        tamanhoPagina: this.quantidadePagina,
        agrupamento,
      };
      this.agrupamentoSelecionado = agrupamento;

      if (this.isAgrupamentoCustomizado(agrupamento)) {
        return;
      }

      buscarTiposAcao(this.filtrosAplicados, this.$resource)
        .then((res) => {
          const tiposAcao = res.data.resposta;
          this.configuraPaginacao(res.data.quantidadeRegistrosPagina);
          this.pagina = res.data.numeroPagina;
          this.carregaParametrosTiposAcao(tiposAcao)
            .then(() => this.carregaLabelCampoCliente(tiposAcao))
            .then(() => {
              this.tiposAcao = tiposAcao;
              this.iniciandoPainel = false;
            });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    configuraPaginacao(quantidadeRegistrosPagina) {
      this.totalPagina = Math.ceil(quantidadeRegistrosPagina / this.quantidadePagina);
    },
  },
  mounted() {
    setTimeout(() => this.$refs.agrupamento.registraBotoesAgrupamento());
  },
};
</script>
